@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FAF9F6;
    z-index: -1;
}

.game-container,
.game {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
}

.row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;
    position: relative;
    width: 400px;
}

.word {
    height: 45px;
    width: 300px;
    border-radius: 5px;
    background-color: lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    z-index: 2;
    box-shadow: none;
    position: relative;
    transition: width 0.4s cubic-bezier(0.34, 1.56, 0.64, 1), background-color 0.4s ease-in, box-shadow 0.3s ease-out;
}

.word svg {
    width: 23px;
    height: 23px;
}

.word.dragging {
    width: 390px;
    box-shadow: 2px 6px 15px 0px rgba(0, 0, 0, 0.3);
}

.row-container.immutable .word {
    background-color: lightsalmon;
}

.row-container.immutable .handle {
    display: none;
}

.handle {
    position: absolute;
    height: 25px;
    width: 30px;
    background-color: #FAF9F6;
}

.handle-icon {
    position: relative;
    z-index: 3;
    height: 100%;
    width: 100%;
    background-color: transparent;
    color: lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
}

.handle:first-of-type {
    left: 5px;
}

.handle:last-of-type {
    right: 5px;
}

.handle-icon.active {
    color: black;
}

.line {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2.5px;
    background-color: lightgray;
    z-index: 0;
    border-radius: 1px;
}

.line:first-of-type {
    left: 18.75px;
}

.line:last-of-type {
    right: 18.75px;
}

.inner-rows {
    list-style: none;
    padding: 0;
    margin: 0;
}

.inner-rows li,
.row-container.immutable:first-of-type {
    margin-bottom: 15px;
}

.modal.instructions .row-container.immutable:first-of-type {
    margin-bottom: 0px;
}

.word input {
    width: 28px;
    height: 24px;
    text-align: center;
    border: none;
    border-bottom: 2px solid grey;
    background-color: transparent;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 400;
    box-sizing: content-box;
    padding: 0;
    border-radius: 0px !important;
}

.word input:focus {
    outline: none;
    border-bottom-color: black;
}

.clue {
    margin-top: 20px;
    width: 340px;
    min-height: 70px;
    border-radius: 10px;
    border: 2px solid lightgrey;
    background-color: rgb(230, 230, 230);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    padding: 15px 20px;
    box-sizing: border-box;
    font-weight: 400;
}

.options {
    margin-bottom: 20px;
    position: relative;
    width: 330px;
    display: flex;
    justify-content: center;
}

.timer {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: 600;
    min-width: 90px;
}

.timer svg {
    width: 24px;
    height: 24px;
}

.options button {
    position: absolute;
    transform: translateY(-50%);
    right: 0;
    top: 50%;
    border-color: lightgray;
    background-color: #FAF9F6;
    overflow: hidden;
    /* position: relative; */
    --cooldown-progress: 0%;
}

.options button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(230, 230, 230);
    width: var(--cooldown-progress);
    transition: width 0.1s linear;
    z-index: -1;
}

.options button span {
    position: relative;
    z-index: 1;
}

.options button:disabled {
    color: gray;
    cursor: not-allowed;
}

.options button:active:not(:disabled) {
    border-color: grey;
}

button {
    font-family: "Poppins";
    background-color: #FAF9F6;
    border: 2px solid gray;
    border-radius: 10px;
    color: black;
    padding: 6px 14px;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
}

button:hover:not(:disabled) {
    background-color: rgb(230, 230, 230);
}

button:active:not(:disabled) {
    border-color: black;
    background-color: lightgray;
}

.row-container:not(.immutable):active .word,
.row-container:not(.immutable) .word:focus-within,
.word.focus {
    background-color: lightsteelblue;
}

footer {
    padding: 0px 20px;
    box-sizing: border-box;
    font-size: 0.8em;
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    width: 100%;
    /* Important for mobile */
    background-color: #FAF9F6;
}

footer p>b {
    font-size: 1.2rem;
}

footer p>b:not(:first-of-type) {
    font-weight: 300;
    margin-left: 15px;
}

footer p:last-of-type b:last-of-type {
    opacity: 0;
}

header {
    flex-shrink: 0;
    width: 100%;
}

main {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

header h1 {
    font-size: 4rem;
    margin: 40px 0 0 0;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 8px;
    transform: translateY(10px);
}

header h3 {
    font-size: 2rem;
    margin: 0;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 8px;
}

header h3 span {
    font-size: 1.5rem;
    letter-spacing: 4px;
    transform: translateY(-6px);
    display: inline-block;
}

header>div {
    position: absolute;
    top: 25px;
    right: 20px;
    left: 20px;
    display: flex;
    justify-content: space-between;
}

#socials {
    display: flex;
    gap: 15px;
}

#socials.takedown {
    gap: 25px;
}

header div svg, #socials svg {
    color: grey;
    width: 30px;
    height: 30px;
}

#socials.takedown svg {
    width: 50px;
    height: 50px;
}

header div svg:hover, #socials svg:hover {
    color: black;
}

.modal-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.modal {
    background-color: #FAF9F6;
    border-radius: 10px;
    border: 2px solid gray;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* max-width: 600px; */
    /* gap: 15px; */
    /* background-color: lightsalmon; */
    /* color: white; */
}

.modal h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2rem;
    letter-spacing: 4px;
    margin: 0;
}

.modal.win>span:nth-of-type(2) {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 7px 0px;
}

.modal.win>span:nth-of-type(2) svg {
    width: 40px;
    height: 40px;
}

.modal.win>span:nth-of-type(2) svg:first-of-type {
    transform: scale(-1, 1);
}

.modal button:last-of-type {
    margin-top: 20px;
}

.modal.win>span:nth-of-type(1) {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1.2rem;
    letter-spacing: 2px;
}

header button {
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

header button svg {
    width: 25px;
    height: 25px;
}

header button:hover svg {
    color: black;
}

.modal.instructions input[type=text]:not(.disabled) {
    color: black !important;
    -webkit-text-fill-color: black !important;
    opacity: 1 !important;
}

.modal.instructions input[type=text].bold {
    border-bottom-color: black !important;
    -webkit-text-fill-color: black !important;
    opacity: 1 !important;
    font-weight: 700;
}

.instruction {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    max-width: 400px;
    margin-top: 20px;
}

.instruction>span:first-of-type {
    margin-left: 10px;
    width: 100%;
    text-align: left;
}

.instruction .clue {
    min-height: min-content;
    margin: 0;
}

.demo {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

#instructions {
    display: flex;
    gap: 30px;
}

.modal.instructions button:last-of-type {
    margin-top: 30px;
}

.word.incorrect::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 2px;
    border-radius: 1px;
    background-color: #C70039;
    animation: fadeOut 3s forwards;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.social {
    display: flex;
    align-items: center;
    justify-content: center;
}

.share-option {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-transform: uppercase;
}

.share-option svg {
    color: grey;
    width: 45px;
    height: 45px;
}

.share-option svg:hover {
    color: black;
}

.share-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.share-options>div {
    display: flex;
    gap: 30px;
}

.mini-game-grid {
    display: grid;
    grid-template-columns: repeat(7, 25px);
    gap: 5px 12px;
    margin-top: 20px;
}

.puzzle-container .mini-game-grid {
    column-gap: 10px;
    margin: 10px 5px 0px;
}

.mini-game-grid span {
    width: 25px;
    height: 25px;
    border-radius: 3px;
    background-color: lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
}

.mini-game-grid span.end-cell {
    background-color: lightsalmon;
}

.mini-game-grid span.post,
.mini-game-grid span.pre.end-cell {
    font-weight: 600;
}

.mini-game-grid span.post:not(.end-cell) {
    background-color: lightsteelblue;
}

.mini-game-grid span.pre {
    border: 2px solid lightsalmon;
}

.mini-game-grid span.end-cell.pre,
.mini-game-grid span.end-cell.post {
    border: 3.5px solid lightsteelblue;
}

.mini-game-grid span.pre::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -2px;
    width: 12px;
    height: 6px;
    z-index: 0;
    background: linear-gradient(to right, lightsalmon 30%, lightsteelblue 70%);
    transform: translate(100%, -50%);
}

.mini-game-grid span.end-cell.pre::after {
    right: -3px;
}

@media screen and (max-width: 900px) {
    header h1 {
        font-size: 3rem;
    }

    .modal.instructions {
        max-height: 80vh;
        overflow-y: scroll;
    }

    #instructions {
        flex-direction: column;
        gap: 0;
    }
}

@media screen and (max-width: 600px) {
    footer {
        flex-direction: column;
        padding: 0px 30px;
        font-size: 0.75rem;
    }

    footer p:first-of-type {
        margin-bottom: 0;
    }

    footer p:last-of-type {
        margin-top: 0;
    }
}

@media screen and (max-width: 450px) {
    header h1 {
        font-size: 2.3rem;
        margin-top: 20px;
    }

    header h3 {
        font-size: 1.5rem;
    }

    header button {
        display: none;
    }

    header>div {
        position: relative;
        justify-content: center;
        top: 0;
        left: 0;
        right: 0;
        margin: 10px 0px;
    }

    .App {
        display: block;
    }

    main {
        margin-bottom: 0;
    }

    main>div>span {
        display: inline-block;
        background-color: grey;
        height: 2px;
        width: calc(100vw - 40px);
    }

    .modal.instructions {
        max-height: fit-content;
        padding: 0px 20px;
        border: none;
        font-size: 0.9rem;
        width: 100vw;
        max-width: 100vw;
        overflow-x: hidden;
        box-sizing: border-box;
        position: relative;
    }

    .clue,
    .demo,
    .row-container {
        max-width: 100%;
    }

    .word {
        max-width: calc(100vw - 120px);
    }

    .instruction {
        max-width: calc(100vw - 40px);
    }

    .modal.instructions h3,
    .modal.instructions>button {
        display: none;
    }
}

#mobile-message {
    font-size: 0.9rem;
    padding: 0px 30px;
    margin-bottom: 0px;
}

.puzzles {
    max-height: 550px;
    border: 2px solid lightgray;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    overflow-y: auto;
    /* gap: 5px; */
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: absolute;
    transform: translateX(-100%);
    top: 0px;
    left: -30px;
}

.puzzle-container {
    border-bottom: 2px solid lightgray;
    padding: 5px 0px;
    position: relative;
    width: 245px;
}

.puzzle-container:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
}

.puzzle-container:first-of-type {
    padding-top: 0;
}

.puzzle-container > div:last-child {
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition: height 0.6s ease-in-out, opacity 0.4s ease-in-out 0.2s;
}

.puzzle-container > div:last-child.active {
    height: 125px;
    opacity: 1;
}

.puzzle {
    width: 100%;
    border: none;
    background-color: none;
    border-radius: 5px;
    font-size: 0.9rem;
    padding: 4px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.puzzle span:last-of-type {
    display: flex;
    align-items: center;
    gap: 5px;
}

.puzzle span:last-of-type > :last-child {
    width: 45px;
    display: flex;
    justify-content: center;
}

.puzzle.solved {
    background-color: lightsalmon;
}

.puzzle.active {
    background-color: lightsteelblue !important;
}

#poster {
    position: fixed;
    background-color: #FAF9F6;
    z-index: 9999;
    padding: 25px;
    padding-bottom: 15px;
}

#poster > div:first-child {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 25px;
}

#poster .mini-game-grid {
    margin: 0;
}

#poster footer {
    margin-top: 10px;
    padding: 0;
}

#poster footer > * {
    margin: 0;
    
}

#poster-title {
    position: absolute;
    left: 50%;
    top: calc(50% - 19px);
    transform: translate(-50%, -50%);
}

#poster-title h1 {
    font-size: 3.5rem;
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 7px;
    transform: translateY(10px);
}

#poster-title h3 {
    font-size: 1.75rem;
    margin: 0;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 7px;
}

#poster-title h3 span {
    font-size: 1.31rem;
    letter-spacing: 3.5px;
    transform: translateY(-6px);
    display: inline-block;
}

#poster-title h4 {
    margin: 20px 0 0 0;
    text-transform: uppercase;
    font-style: italic;
    letter-spacing: 1px;
    font-size: 1.15rem;
    font-weight: 300;
}

.modal.takedown {
    max-width: 370px;
}